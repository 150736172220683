import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Link } from "gatsby";

function calculateLink(text) {
    text = text.trim();
    switch (text) {
        case "Copyediting":
            return "/services#editing";
        case "Proofreading":
            return "/services#proof-reading";
        case "Academic editing":
            return "/services#academic-editing";
        case "Editorial Assessment":
            return "/services#editorial-assessment";
        default:
            return "/services";
    }
}

function renderColoredText(text) {
    text = text.trim();
    switch (text) {
        case "Copyediting":
            return (
                <h4 className="has-text-centered mb-0">
                    <span className="has-text-warning">Copy</span>
                    <span className="has-text-black">editing</span>
                </h4>
            );
        case "Proofreading":
            return (
                <h4 className="has-text-centered mb-0">
                    <span className="has-text-warning">Proof</span>
                    <span className="has-text-black">reading</span>
                </h4>
            );
        case "Academic editing":
            return (
                <h4 className="has-text-centered mb-0">
                    <span className="has-text-warning">Academic</span>
                    <span className="has-text-black"> editing</span>
                </h4>
            );
        case "Editorial Assessment":
            return (
                <h4 className="has-text-centered mb-0">
                    <span className="has-text-warning">Editorial</span>
                    <span className="has-text-black"> assessment</span>
                </h4>
            );
        default:
            console.log("rendering default");
            return <h4 className="has-text-centered has-text-black">{text}</h4>;
    }
}

const FeatureGrid = ({ gridItems }) => (
    <div className="columns is-multiline">
        {gridItems.map((item) => (
            <div key={item.text} className="column is-6">
                <section className="section">
                    <div className="has-text-centered">
                        <div
                            style={{
                                width: "240px",
                                display: "inline-block",
                            }}
                        >
                            <Link title={item.text} to={calculateLink(item.text)}>
                                <PreviewCompatibleImage imageInfo={item} />
                            </Link>
                        </div>
                    </div>
                    {renderColoredText(item.text)}
                </section>
            </div>
        ))}
    </div>
);

FeatureGrid.propTypes = {
    gridItems: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            text: PropTypes.string,
        })
    ),
};

export default FeatureGrid;
