import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import logoHeader from "../img/logo-header-resized.png";
import Testimonials from "../components/Testimonials";

export const IndexPageTemplate = ({
    image,
    title,
    subheading,
    mainpitch,
    description,
    testimonials,
    publications,
    intro,
}) => (
    <div>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
        </Helmet>
        <div
            className="full-width-image margin-top-0"
            style={{
                backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image})`,
                backgroundAttachment: `fixed`,
                backgroundPosition: `center`,
            }}
        >
            <div
                id="main-header"
                style={{
                    display: "flex",
                    lineHeight: "1",
                    justifyContent: "space-around",
                    alignItems: "left",
                    flexDirection: "column",
                }}
            >
                <img id="main-header_logo" src={logoHeader} alt="Read Panda logo" />
                <h3
                    className="has-text-centered has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                    style={{
                        backgroundColor: "#b70d31",
                        color: "white",
                        lineHeight: "1",
                        padding: "0.25em",
                        width: "100%",
                    }}
                >
                    {subheading}
                </h3>
            </div>
        </div>
        <section className="section section--gradient has-background-white">
            <div className="container">
                <div className="section">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="content">
                                <div className="content">
                                    <div className="tile">
                                        <h1 className="title has-text-black">{mainpitch.title}</h1>
                                    </div>
                                    <div className="tile">
                                        <h3 id="home-pitch" className="subtitle has-text-black">
                                            {mainpitch.description}
                                        </h3>
                                    </div>
                                </div>
                                <Features gridItems={intro.blurbs} />
                                <div className="columns">
                                    <div className="column is-12 has-text-centered">
                                        <Link className="button is-warning" to="/services">
                                            See all services
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="publications">
                <div id="publications_scrolling-wrapper">
                    {publications.map((p, i) => (
                        <div key={`pub_${i}`} className="publication-card">
                            <a
                                // className="tile"
                                key={`pub-link_${i}`}
                                href={p.link}
                                target="_blank"
                                rel="noopener"
                                style={{ height: "340px", width: "220px" }}
                            >
                                <img
                                    key={`pub-image_${i}`}
                                    style={{
                                        height: "340px",
                                        width: "220px",
                                    }}
                                    src={
                                        !!p.image && !!p.image.childImageSharp
                                            ? p.image.childImageSharp.fixed.src
                                            : p.image
                                    }
                                    alt={p.text}
                                />
                            </a>
                        </div>
                    ))}
                </div>
            </section>
            <div className="container">
                <div className="section">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="content">
                                {/* testimonials */}
                                <Testimonials testimonials={testimonials} />
                            </div>
                        </div>
                    </div>
                    {/* blog roll */}
                    <div id="blog-roll" className="column is-12">
                        <h3 className="has-text-weight-semibold  has-text-primary is-size-2">
                            Latest stories
                        </h3>
                        <BlogRoll indexPageVersion={true} />
                        <div className="column is-12 has-text-centered">
                            <Link className="button is-warning" to="/blog">
                                Read more stories
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
);

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    subheading: PropTypes.string,
    mainpitch: PropTypes.object,
    description: PropTypes.string,
    intro: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
    testimonials: PropTypes.array,
    publications: PropTypes.array,
};

const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout>
            <IndexPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                subheading={frontmatter.subheading}
                mainpitch={frontmatter.mainpitch}
                description={frontmatter.description}
                intro={frontmatter.intro}
                testimonials={frontmatter.testimonials}
                publications={frontmatter.publications}
            />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                subheading
                mainpitch {
                    title
                    description
                }
                description
                intro {
                    blurbs {
                        image {
                            childImageSharp {
                                fluid(maxWidth: 240, quality: 64) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        text
                    }
                    heading
                    description
                }
                testimonials {
                    author
                    quote
                }
                publications {
                    image {
                        childImageSharp {
                            fixed(width: 220, height: 340) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                    text
                    link
                }
            }
        }
    }
`;
